import React from 'react'

function Button({name,handleClick, classNames}) {
  return (
    <button
          onClick={handleClick}
          type="submit"
          className={`${classNames} py-2 rounded-md`}
        >
         {name}
        </button>
  )
}

export default Button