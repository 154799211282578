import React from 'react';

const Toast = ({ message }) => {

  return (
    <div className="fixed top-2 w-1/2 py-4 text-center bg-btn-blue text-black px-4 rounded-full shadow-lg">
      {message}
    </div>
  );
};

export default Toast;
