// DeclarationForm.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../General/Logo";

const PaymentCart = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [showPets, setShowPets] = useState(state?.results?.applications)
    const checkoutHandler = async () => {
      const url = `${process.env.REACT_APP_ROUTE}/user/checkout`;
      
      const ids = showPets.map((pet) => pet.message==="Application Amount as all certificates available" && pet.applicationID).filter(Boolean);
      let result;
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({applicationIds:ids }),
        });
        result = await res.json();
      } catch (error) {
        console.error("Error:", error);
        return
      }
      const options = {
          key:process.env.REACT_APP_RAZORPAY_KEY,
          amount: result.totalAmount,
          currency: "INR",
          name: "Four Paws",
          description: "Tutorial of RazorPay",
          image: "https://raw.githubusercontent.com/Wasserstoff-Innovation/fourpaws_frontend/Gourav/src/assets/logo/Vector.png?token=GHSAT0AAAAAACSVRF6KC6JRO6EZAU2KUKOKZTHBRCA",
          order_id: result.orderId,
          // callback_url: `${process.env.REACT_APP_ROUTE}/razorpay/webhook`,
          handler: function (response){
            console.log("handler: ",response)
            // alert(response.razorpay_payment_id);
            // alert(response.razorpay_order_id);
            // alert(response.razorpay_signature)
            navigate("/applicationIds", {state: state?.results?.applications})
        },
          prefill: {
              name: showPets[0].ownerName,
              email: showPets[0]?.ownerEmail,
              contact: showPets[0]?.ownerContactNo
          },
          notes: {
              "address": "Razorpay Corporate Office"
          },
          theme: {
              "color": "#121212"
          }
      };
      const razor = new window.Razorpay(options);
      razor.open();
  }
  return (
    <div className="min-h-screen flex flex-col p-4 items-center">
      <Logo />
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-4xl font-semibold mb-4">Payment</h1>
      </div>
      <div className=" w-full max-w-5xl md:flex  justify-start mt-1 ">
        <div className="flex flex-col w-full mr-10 mb-4 md:mb-0 bg-white rounded-lg  p-4 shadow-lg text-gray-500">
          <div className="flex justify-between ml-4 mr-16">
            <h1>Pet Details</h1>
            <h1>Message</h1>
            <h1 className="">Price</h1>
          </div>

          <div className="mr-16 mt-4">
            {showPets?.map((pet, index) => (
              <div key={pet.dogId} className="flex justify-between p-4">
                <div className="flex justify-between w-full items-center">
                <div className="flex flex-wrap">
                  <img
                    src={pet?.Image}
                    alt={pet.dogName}
                    className="w-16 h-16 rounded-full mr-4"
                  />
                  <div className="flex flex-col justify-center">
                    <h2 className="font-semibold text-black">{pet.dogName}</h2>
                    <button
                      onClick={() => {setShowPets((prev) => {
                       return prev.filter((dog) => {return dog.dogId !== pet.dogId})
                      } )}}
                      className="text-gray-500"
                    >
                      Remove
                    </button>
                    </div>
                  
                </div>
                <div className="">
                  <p className="text-black font-semibold text-sm w-64" key={pet.dogId}>
                    {pet?.message}
                  </p>
                </div>
                <div>
                  <p className="text-black font-semibold" key={pet.dogId}>
                    {pet?.amount}
                  </p>
                </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col w-full md:w-1/2 ml-auto justify-center bg-white rounded-lg  p-4 px-16 shadow-lg">
          <h1 className=" text-center text-xl mt-4 font-bold">
            Payment Summary
          </h1>
          <div className="flex text-gray-400 mt-8">
            <p>Pet</p>
            <p className="ml-auto">{showPets?.length}</p>
          </div>
          
          <div className="flex text-gray-400 mt-5 mb-20">
            <p>Amount</p>
            <p className="ml-auto">{state?.results?.totalAmount}</p>
          </div>
          <hr />
          <div className="flex">
            <p>Total Amount</p>
            <p className="ml-auto">{state?.results?.totalAmount}</p>
          </div>

          <button
            onClick={checkoutHandler}
            type="submit"
            disabled={state?.results?.totalAmount === 0}
            className="mt-14 mb-2 w-full ml-auto px-6 py-2 bg-btn-blue text-black rounded-md"
          >
            Proceed To Payment
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentCart;
