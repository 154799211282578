// DeclarationForm.js
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Logo from "../General/Logo";

const Declaration = () => {
    const [checkedItems, setCheckedItems] = useState({
        item1: false,
        item2: false,
        item3: false,
        item4: false,
        item5: false,
        item6: false,
        item7: false,
        item8: false,
    });

    const [checkAll, setCheckAll] = useState(false);

    let navigate = useNavigate();

    const allChecked = Object.values(checkedItems).every(Boolean);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        if (name === "checkAll") {
            const updatedCheckedItems = Object.keys(checkedItems).reduce((acc, key) => {
                acc[key] = checked;
                return acc;
            }, {});
            setCheckedItems(updatedCheckedItems);
            setCheckAll(checked);
        } else {
            setCheckedItems({
                ...checkedItems,
                [name]: checked,
            });

            if (!checked) {
                setCheckAll(false);
            } else {
                setCheckAll(Object.values({ ...checkedItems, [name]: checked }).every(Boolean));
            }
        }
    };

    return (
        <div className="flex justify-center p-4 max-h-screen">
            <div className="max-w-5xl">
                <Logo />
                <div className="w-full mt-16 max-w-4xl flex justify-start items-center">
                    <h1 className="text-4xl font-semibold mb-4">Declaration</h1>
                </div>

                <div className="bg-white shadow-lg mt-1 rounded-lg p-4">
                    <form>
                        <div className="space-y-2 text-sm">
                            
                            {Object.keys(checkedItems).map((item, index) => (
                                <label key={index} className="flex items-start space-x-2">
                                    <input
                                        type="checkbox"
                                        name={item}
                                        checked={checkedItems[item]}
                                        onChange={handleCheckboxChange}
                                        className="mt-1"
                                    />
                                    <span>
                                        {index === 0 && 'I will take proper treatment, care and shelter of his pet dog.'}
                                        {index === 1 && 'I will ensure 5 freedom of animals, as decided by the court of law.'}
                                        {index === 2 && 'I will follow Bye laws of Haryana Municipal Act-1994. (iv) I will follow all Circular guidelines and advisories which is issued by Animal Welfare Board of India. (AWBI).'}
                                        {index === 3 && 'I will follow all communications conveyed by the District Society for Prevention of Cruelty to Animals(DSPCA), Gurugram.'}
                                        {index === 4 && 'I will ensure for proper Anti-Rabies vaccination of his pet dog as per instructions of veterinary surgeon.'}
                                        {index === 5 && 'I will ensure that my pet dog shall wear a collar to which shall be attached a metal token.'}
                                        {index === 6 && 'I shall keep the dog on leash when brought out at public places and it is guarded so that it does not bite anyone. I will also ensure that my dog shall also carry an eco friendly disposable bag for scooping the poo/shit of the dog and dispose off it properly to maintain hygiene and cleanliness at public places.'}
                                        {index === 7 && 'I also confirm that there is no pending litigation OR Court Case in any Hon\'ble Court in India relating to my Pet Dog.'}
                                    </span>
                                </label>
                            ))}
                            <label className="flex items-start space-x-2">
                                <input
                                    type="checkbox"
                                    name="checkAll"
                                    checked={checkAll}
                                    onChange={handleCheckboxChange}
                                    className="mt-1"
                                />
                                <span>I accept all the terms</span>
                            </label>
                        </div>
                    </form>
                </div>
                <div className="w-full max-w-5xl flex justify-start items-center mt-4 mb-8">
                    <p className="mt-4 text-center text-gray-500 text-xs">*You agree this as an affidavit</p>
                    <button
                        type="button"
                        className={`bg-blue-500 ml-auto text-white px-6 py-1 rounded ${allChecked ? '' : 'opacity-50 cursor-not-allowed'}`}
                        disabled={!allChecked}
                        onClick={() => navigate('/preview')}
                    >
                        Preview
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Declaration;
