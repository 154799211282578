import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addDetails, clearAll } from "../../redux/features/ownerDetailsSlice";
import manWithDog from "../../assets/main/unsplash_v3-zcCWMjgM.png";

import Logo from "../General/Logo";
import Button from "../General/Button";
import Input from "../General/Input";
import Toast from "../General/Toast";

const OwnerInformation = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { ownerDetails } = useSelector((state) => state.ownerDetails);
  const [toastVisible, setToastVisible] = useState(false);

  const [ownerDetail, setOwnerDetail] = useState(
    localStorage.getItem("owner") ? JSON.parse(localStorage.getItem("owner")) : 
    {
      id: 1,
      name: "",
      FatherName: "",
      Address: "",
      Email: "",
      contact: "",
      dogs: "",
    },
  );

  localStorage.setItem("owner", JSON.stringify(ownerDetail))

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (name === 'contact' && !/^\d{0,10}$/.test(value)) {
        return;
      }
      if (name === 'dogs' && (Number(value) < 1 && value !== "" || isNaN(value))) {
        return;
      }
      setOwnerDetail({ ...ownerDetail, [name]: value });
    };

  const handleNext = () => {
    let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!emailFormat.test(ownerDetail.Email) ) {
      setToastVisible(true);
      setTimeout(() => setToastVisible(false), 4000)
      return;
    }
    if (
      ownerDetail.name === undefined ||
      ownerDetail.FatherName === undefined ||
      ownerDetail.Address === undefined ||
      ownerDetail.Email === undefined ||
      ownerDetail.contact === undefined ||
      ownerDetail.dogs === undefined
    ) {
      setToastVisible(true);
      setTimeout(() => setToastVisible(false), 2000)
      return;
    }
    navigate("/application");
    dispatch(clearAll());
    dispatch(addDetails(ownerDetail));
  };

  return (
    <div className="max-h-screen flex flex-col p-4 items-center">
      <Logo />
      <div className="w-full max-w-5xl mt-4 flex justify-start items-center">
        <h1 className="text-3xl font-semibold mb-4">Application Form</h1>
      </div>
      {toastVisible && <Toast message="Please Fill out all the fields with a valid Email Address" />}
      <div className="bg-white  p-3 rounded-lg shadow-md w-full max-w-5xl flex flex-col md:flex-row items-center">
        <form className="w-full md:pr-10">
          <h1 className="font-bold">Owner's Details</h1>
          <p className="text-xs">Fill out these details</p>
          <div className="mb-2 flex space-x-4">
            <Input
              name={"name"}
              placeholderName={"Name"}
              val={ownerDetail.name}
              classNames={"mt-1 w-1/2 rounded-md border-gray-300"}
              handleChange={handleInputChange}
            />
            <Input
              name={"FatherName"}
              placeholderName={"Father/Husband Name"}
              val={ownerDetail.FatherName}
              classNames={"mt-1 w-1/2 rounded-md border-gray-300"}
              handleChange={handleInputChange}
            />
          </div>
          <div className="mb-2">
            <textarea
              name="Address"
              onChange={handleInputChange}
              value={ownerDetail.Address}
              placeholder="Residential Address In Municipal Corporation"
              className="mt-1 min-h-16 max-h-32 p-2 w-full border border-gray-300 rounded-md"
            ></textarea>
          </div>
          <div className="mb-2">
            <input
              type="email"
              name="Email"
              onChange={handleInputChange}
              value={ownerDetail.Email}
              placeholder="Email"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            />
          </div>
          <div className="mb-2 flex space-x-4">
            <input
              type="tel"
              name="contact"
              value={ownerDetail.contact}
              onChange={handleInputChange}
              placeholder="Contact No."
              className="mt-1 p-2 w-1/2 border border-gray-300 rounded-md"
              maxLength="10"
              pattern="\d{10}"
            />
            <input
              type="number"
              name="dogs"
              value={ownerDetail.dogs}
              onChange={handleInputChange}
              placeholder="No. of Dogs"
              className="mt-1 p-2 w-1/2 border border-gray-300 rounded-md"
              min="1"
            />
          </div>

        </form>
        <div className="w-full h-full">
          <img
            src={manWithDog}
            alt="Man with Dog"
            className="w-full h-auto mt-2 md:mt-0 object-cover"
          />
        </div>
      </div>
      <div className="w-full max-w-5xl flex justify-start items-center mb-8">
        <p className="">*Once submitted form will not be edited again.</p>

        <Button
          handleClick={handleNext}
          name={"Next"}
          classNames={"mt-4 ml-auto px-6 py-2 bg-btn-blue text-black"}
        />
      </div>
    </div>
  );
};

export default OwnerInformation;
