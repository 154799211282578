import { configureStore } from '@reduxjs/toolkit';
import ownerDetailsReducer from './features/ownerDetailsSlice';
import applicationReducer from './features/applicationSlice';



const store = configureStore({
  reducer: {
    ownerDetails: ownerDetailsReducer,
    application: applicationReducer
  },
  middleware:(getDefaultMiddleware)=>getDefaultMiddleware({
    serializableCheck:false
  })
});

export default store;