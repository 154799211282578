import React from 'react'

function Input({name, placeholderName, val, handleChange, classNames}) {
  return (
    <input
              type="text"
              name={name}
              placeholder={placeholderName}
              value={val}
              onChange={handleChange}
              className={`border ${classNames} p-2 rounded`}
            />
  )
}

export default Input