import React from "react";
import formLogo from "../../assets/logo/image 4.png";

function McgApplication({
  dogImage,
  dogName,
  Gender,
  Breed,
  Colour,
  Age,
  vaccinationCard,
  sterilizationCard,
  ownerName,
  ownerFName,
  ownerEmail,
  ownerContactNo,
  ownerAddress
}) {
  return (
    <div id="component-to-download" className="min-w-[1000px]">

    {/* Application */}


      <form className="bg-white p-10  md:px-20 ">
        <div className="flex flex-col mb-2 items-center">
          <img
            src={formLogo}
            alt="Muncipal Logo"
            className="w-48 h-full mb-4 object-cover"
          />

          <h3 className="text-xl font-semibold mb-4">
            Application for registration of dogs
          </h3>
        </div>

        <div className="flex">
          <div>
            <div className=" mb-4">
              <h1 className="font-bold text-gray-700">To</h1>
              <p className="max-w-4xl ml-5 mx-auto">
                Licensing Officer, <br />
                Municipal Corporation, <br /> Gurugram.
              </p>
            </div>

            <div className="mb-10">
              <h1 className="font-bold text-gray-700">
                Sub: Application for registration of dogs
              </h1>
            </div>
          </div>
          
          <img
            src={dogImage}
            alt="Photo"
            className="w-28  h-32 mr-10 ml-auto border-2 border-black"
          />
        </div>

        <div className="mb-10 max-w-4xl mx-auto">
          <h1 className="text-gray-700">
            Kindly issue License of Registration of pet dog in Municipal
            Corporation, Gurugram area as per following details:
          </h1>
        </div>
        <div className="mb-10">
          <h1 className="font-bold underline text-gray-700">
            A. Application Details:-
          </h1>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="flex  mb-4">
            <label className=" w-1/4 mr-10 pb-1 text-gray-700">
              1. Name of applicant
            </label>
            :
            <input
              type="text"
              className=" border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={ownerName}
              readOnly
            />
          </div>

          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">
              2. Father's/husband's Name
            </label>
            :
            <input
              type="text"
              className="border-black w-3/4 border-t-0 border-l-0 border-r-0 border-b-2  px-1"
              value={ownerFName}
              readOnly
            />
          </div>

          <div className="flex mb-4">
            <label className="block mr-10 w-1/4 text-gray-700">
              3. Residential address in municipal corporation
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={ownerAddress}
              readOnly
            />
          </div>

          <div className="flex mb-4">
            <label className="block mr-10 w-1/4 text-gray-700">
              4. Contact No. & Email Address
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={
                ownerContactNo + " & " + ownerEmail
              }
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">
              5. Name of Dog
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={dogName}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">6. Gender</label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={Gender}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">
              7. Breed of Dog.
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={Breed}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">
              8. Colour of Dog
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={Colour}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 pb-1 w-1/4 text-gray-700">
              9. Age of the Dog
            </label>
            :
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={Age}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 w-1/4 text-gray-700">
              10. Has the dog been immunized till date? : (Vaccination Card
              -Mandatory)
            </label>
                
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={vaccinationCard}
              readOnly
            />
          </div>
          <div className="flex mb-4">
            <label className="block mr-10 w-1/4 text-gray-700">
              11. If dog is 4 years old or above, Attach Sterilization
              Certificate(Mandatory,)
            </label>
            <input
              type="text"
              className="border w-3/4 border-black border-t-0 border-l-0 border-r-0 border-b-2 px-1"
              value={sterilizationCard}
              readOnly
            />
          </div>
        </div>

        <div className="mb-10 mt-10">
          <h1 className="font-bold underline text-gray-700">
            B. Enclosures / Attachments:-
          </h1>
        </div>

        <div className="max-w-4xl mx-auto">
          <ul style={{ listStyle: "decimal" }}>
            <li>2 Passport Size Photographs of Dog</li>
            <li>1 Photograph of dog with owner</li>
            <li>Owner’s ID Proof with local address</li>
            <li>Veterinary Health Certificate of Dog</li>
            <li>Vaccination Card of Dog</li>
            <li>Sterilization Certificate of Dog if required.</li>
          </ul>
        </div>

        <div className="flex mt-16  w-full max-w-4xl mx-auto">
          <h1>Date:</h1>
          <h1 className="ml-auto mr-10">Signnature of Applicant</h1>
        </div>
      </form>

      {/* Affidavit */}

      <form className="bg-white mt-10 p-10 md:px-20">
        <h1 className="text-center mb-8">Affidavit</h1>

        <div className="flex mb-4">
          <label className=" text-gray-700 mb-1">I</label>
          <input
            type="text"
            className=" border text-center w-1/4 border-black border-t-0 border-l-0 border-r-0 border-b-2"
            value={ownerName}
            readOnly
          />
          <label className=" text-gray-700">S/W/o</label>
          <input
            type="text"
            className=" border w-1/4 text-center border-black border-t-0 border-l-0 border-r-0 border-b-2"
            value={ownerFName}
            readOnly
          />
          <label className=" text-gray-700">resident of</label>
          <input
            type="text"
            className=" border w-1/4 text-center border-black border-t-0 border-l-0 border-r-0 border-b-2"
            value={ownerAddress}
            readOnly
          />
          <label>will ensure</label>
        </div>

        <label>
          strict compliance of the following instructions/guidelines/advisories:
        </label>

        <div className="max-w-4xl mt-8 mx-auto">
          <ul style={{ listStyle: "lower-roman" }}>
            <li>
              I will take proper treatment, care and shelter of his pet dog
            </li>
            <li>
              I will ensure 5 freedom of animals, as decided by the court of
              law.
            </li>
            <li>I will follow Bye laws of Haryana Municipal Act-1994.</li>
            <li>
              I will follow all Circular guidelines and advisories which is
              issued by Animal Welfare Board of India. (AWBI).
            </li>
            <li>
              I will follow all communications conveyed by the District Society
              for Prevention of Cruelty to Animals(DSCPA), Gurugram.
            </li>
            <li>
              I will ensure for proper Anti-Rabies vaccination of his pet dog as
              per instructions of veterinary surgeon.
            </li>
            <li>
              I will ensure that my pet dog shall wear a collar to which shall
              be attached a metal token.
            </li>
            <li>
              I shall keep the dog on leash when brought out at public places
              and it is guarded so that it does not bite anyone. I will also
              ensure that my dog shall also carry an eco friendly disposable bag
              for scooping the poo/shit of the dog and dispose off it properly
              to maintain hygiene and cleanliness at public places.
            </li>
            <li>
              I also confirm that there is no pending litigation OR Court Case
              in any Hon’ble Court in India relating to my Pet Dog.
            </li>
          </ul>
        </div>

        <div className="flex mt-16  w-full max-w-4xl mx-auto">
          <h1>Date:</h1>
          <h1 className="ml-auto mr-10">Signnature of Applicant</h1>
        </div>
      </form>
    </div>
  );
}

export default McgApplication;