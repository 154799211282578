// src/components/ApplicationList.js
import React, { useState } from "react";
import Logo from "../General/Logo";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addPets } from "../../redux/features/applicationSlice";
import { clearAll } from "../../redux/features/applicationSlice";
import { addDetails } from "../../redux/features/ownerDetailsSlice";


const formatDate = (isoString) => {
  const date = new Date(isoString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${day}/${month}/${year}`;
};

const ApplicationList = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const getStatusClass = (status) => {
    switch (status) {
      case 'success':
        return 'text-green-700 bg-green-100';
      case 'Rejected':
        return 'text-red-700 bg-red-100';
      case 'pending':
        return 'text-yellow-700 bg-yellow-100';
      default:
        return '';
    }
  };
  const [application, setApplication] = useState(true);

  const handleDraft = () => {
    setApplication(false);
  };
  const handleApplication = () => {
    setApplication(true);
  };

  const handleStatus = async (application) => {
    const url =
      `${process.env.REACT_APP_ROUTE}/user/getdogs`;

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ applicationId: application }),
      });

      const result = await res.json();
      if (result.message === "returning data for applicationID") {
        navigate("/applicationStatus", { state: { result } });
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Invalid Application Id");
      return;
    }
  };


  const handleEdit = async (application) => {
    const petData = {
      id: 1,
      name: application?.dog?.name,
      gender: application?.dog?.gender,
      applicationId:application?.applicationID,
      age: application?.dog?.age,
      colour: application?.dog?.color,
      breed: application?.dog?.breed,
      vaccinationCard: application?.dog?.vaccinationCard,
      sterilizationCard: application?.dog?.sterLizationCard,
      veterinaryCard: application?.dog?.veterinary,
      stage: "Payment",
      files: application?.dog?.images,
    };
    const ownerData = {
      id: state?.result?.getData?.id,
      name: state?.result?.getData?.name,
      FatherName: state?.result?.getData?.fName,
      Address: state?.result?.getData?.address,
      Email: state?.result?.getData?.email,
      contact: state?.result?.getData?.contactNo,
      dogs: state?.result?.getData?.dogsCount,
    }
    dispatch(clearAll());
    localStorage.removeItem("owner")
    localStorage.removeItem("application")
    localStorage.setItem("owner", JSON.stringify(ownerData))
    localStorage.setItem("application", JSON.stringify([petData]))
    // dispatch(addDetails(ownerData))
    // dispatch(addPets([petData]))
    navigate("/application", {state: {addBtn: "false"}});
  }

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <Logo />
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-4xl font-semibold mb-4">Application List</h1>
      </div>
      <div className="w-full max-w-5xl mb-4 mt-10 px-4 flex justify-start items-center">
        <h1
          onClick={handleApplication}
          className={`font-semibold ${
            application ? "bg-btn-blue" : ""
          } p-2 px-4 rounded cursor-pointer mr-10`}
        >
          All Application
        </h1>
        <h1
          onClick={handleDraft}
          className={`font-semibold ${
            !application ? "bg-btn-blue" : ""
          } p-2 px-4 rounded cursor-pointer mr-10`}
        >
          Draft
        </h1>
      </div>
      <div className="max-w-5xl w-full">
      <table className="min-w-full rounded-sm bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b border-gray-200">S.no</th>
            <th className="py-2 px-4 border-b border-gray-200">Dog Name</th>
            <th className="py-2 px-4 border-b border-gray-200">Form Id</th>
            <th className="py-2 px-4 border-b border-gray-200">{application? "Status" : "Edit"}</th>
            <th className="py-2 px-4 border-b border-gray-200">{application? "Date of registration" : "Delete"}</th>
          </tr>
        </thead>
        <tbody>
        {application
          ? state?.result?.getData?.applications?.map((app, index) => (
            app.paymentId !== null  && <tr onClick={() => handleStatus(app.applicationID)} className=" cursor-pointer" key={index}>
              <td className="py-2 px-4 border-b text-center border-gray-200">{index + 1}</td>
              <td className="py-2 px-4 border-b text-center border-gray-200">{app.dog?.name}</td>
              <td className="py-2 px-4 border-b text-center border-gray-200">Id -{app.applicationID}</td>
              <td className={`py-2 px-4 border-b text-center border-gray-200 ${getStatusClass(app.status)}`}>
                {app.status}
              </td>
              <td className="py-2 px-4 border-b text-center border-gray-200">{formatDate(app.createdAt)}</td>
            </tr>
          ))
        
          : state?.result?.getData?.applications?.map((app, index) => (
            app.paymentId === null  && <tr key={index}>
              <td className="py-2 px-4 border-b text-center border-gray-200">{index + 1}</td>
              <td className="py-2 px-4 border-b text-center border-gray-200">{app.dog?.name}</td>
              <td className="py-2 px-4 border-b text-center border-gray-200">Id -{app.applicationID}</td>
              <td  onClick={() => handleEdit(app)} className={`py-2 px-4 text-center border-b hover:underline cursor-pointer border-gray-200`}>Edit</td>
              <td className="py-2 px-4 border-b text-center cursor-pointer hover:underline border-gray-200">Delete</td>
                </tr>
            ))}
            </tbody>
      </table>
      </div>
    </div>
  );
};

export default ApplicationList;
