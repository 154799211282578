import React, { useState } from "react";
import Logo from "../General/Logo";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import McgApplication from "../General/McgApplication";
import blackTick from "../../assets/icon/blackTick.png";
import Button from "../General/Button";

const Preview = () => {
  let navigate = useNavigate();
  const { application } = useSelector((state) => state.application);
  const applicationInfo = JSON.parse(localStorage.getItem("application"))
  const ownerInfo = JSON.parse(localStorage.getItem("owner"))
  const [selectedDog, setSelectedDog] = useState(applicationInfo?.[0]);
  // const { ownerDetails } = useSelector((state) => state.ownerDetails);



  const handleEdit = () => {
    navigate("/ownerDetails");
  };
  const handlePayment = async () => {
    if (application?.length === 0) return;
    let url = `${process.env.REACT_APP_ROUTE}/user`;
    if (applicationInfo?.[0]?.applicationId)
      url = `${process.env.REACT_APP_ROUTE}/user/edit/${applicationInfo?.[0]?.applicationId}`;

    const data = {
      name: ownerInfo?.name,
      fName: ownerInfo?.FatherName,
      address: ownerInfo?.Address,
      email: ownerInfo?.Email,
      contactNo: ownerInfo?.contact,
      dogsCount: applicationInfo?.length,
      dogs: applicationInfo?.map((pet) => ({
        name: pet.name,
        gender: pet.gender,
        age: +pet.age,
        image: pet.files,
        color: pet.colour,
        breed: pet.breed,
        vaccinationCard: pet.vaccinationCard,
        sterLizationCard: pet.sterilizationCard,
        veterinary: pet.veterinaryCard,
      })),
    };

    // console.log(data)
    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await res.json();
      const results = result.data;
      if (
        result.message === "Data Inserted Successfully" ||
        result.message === "Data Updated Successfully"
      ) {
        navigate("/payment", { state: { results } });
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error("Error:", error);
      return;
    }
  };

  console.log(applicationInfo)

  return (
    <div className="p-6 min-h-screen">
      <div className="max-w-5xl mx-auto ">
        <Logo />

        <div className="flex mb-6">
          <h1 className="text-4xl">Preview</h1>
          <Button
            handleClick={handleEdit}
            name={"Edit"}
            classNames={"ml-auto  mt-4 px-6 bg-btn-blue text-black"}
          />
        </div>

        <div className="flex mb-8">
          {applicationInfo
            ? applicationInfo.map((dog) => (
                <div className="mr-4" key={dog.id}>
                  <div
                    className={`relative overflow-hidden `}
                    onClick={() => setSelectedDog(dog)}
                  >
                    <img
                      src={dog?.files?.passport}
                      alt={dog?.name}
                      className={`w-20 h-20 rounded-full cursor-pointer object-cover ${
                        selectedDog.id === dog.id ? "opacity-40" : "opacity-100"
                      }`}
                    />
                    {selectedDog.id === dog.id && (
                      <img
                        src={blackTick}
                        alt="tick"
                        className="w-5 opacity-100 h-5 absolute top-1/4 mt-3 ml-2 left-1/4 object-cover"
                      />
                    )}
                  </div>
                  <p className="font-semibold text-center">{dog?.name}</p>
                </div>
              ))
            : null}
          <Button
            handleClick={handlePayment}
            name={"Payment"}
            classNames={"ml-auto h-1/2 mt-4 px-6 bg-btn-blue text-black"}
          />
        </div>

        <div className="overflow-x-auto">
          <McgApplication
          dogImage={applicationInfo ? selectedDog?.files?.passport: ""}
            dogName={applicationInfo ? selectedDog.name : ""}
            Gender={applicationInfo ? selectedDog.gender : ""}
            Breed={applicationInfo ? selectedDog.breed : ""}
            Colour={applicationInfo ? selectedDog.colour : ""}
            Age={applicationInfo ? selectedDog.age : ""}
            vaccinationCard={applicationInfo ? selectedDog.vaccinationCard : ""}
            sterilizationCard={
              applicationInfo ? selectedDog.sterilizationCard : ""
            }
            ownerName={ownerInfo ? ownerInfo.name : ""}
            ownerFName={ownerInfo ? ownerInfo.FatherName : ""}
            ownerEmail={ownerInfo ? ownerInfo.Email : ""}
            ownerContactNo={ownerInfo ? ownerInfo.contact : ""}
            ownerAddress={ownerInfo ? ownerInfo.Address : ""}
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
