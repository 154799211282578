import React, { useEffect } from 'react'
import "../Style/Font.css";
import logo from "../../assets/logo/Vector.png";
import Button from './Button';
import { useNavigate } from 'react-router-dom';


function Logo() {
  let navigate = useNavigate();
  
  const handleRefill = () => {
    localStorage.removeItem("owner")
    localStorage.removeItem("application")
    navigate('/ownerDetails')
  }
  return (
    <div className="w-full max-w-5xl flex justify-start items-center mb-6">
          <img src={logo} alt="Logo" className="w-8 h-18" />
          <h1 className="ml-2 fnt text-2xl">FourPaws</h1>
          <Button
            handleClick={handleRefill}
            name={"Refill Again"}
            classNames={"ml-auto  mt-4 px-6 bg-btn-blue text-black"}
          />
        </div>
  )
}

export default Logo