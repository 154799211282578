import React, { useState } from "react";
import upload from "../../assets/icon/upload.png";
import dog from "../../assets/dog/dog profile.png";
import info from "../../assets/icon/info.png";
import { useNavigate,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addPets } from "../../redux/features/applicationSlice";
import { clearAll } from "../../redux/features/applicationSlice";
import Logo from "../General/Logo";
import back from "../../assets/icon/Back.png";
import Dropdown from "../General/Dropdown";
import Button from "../General/Button";
import Input from "../General/Input";
import axios from 'axios';
import Loader from "../General/Loader";
import Toast from "../General/Toast";

const enclosure = {
 passport : "1 Passports Of Dogs",
  ownerDog: "1 Photo Of Owner With Dogs",
  ownerId: "Owner Id Proof",
  veterinary: "Veterinary Health Certificate Of Dog",
  vaccination: "Vaccination Card Of Dog",
  sterlization: "Sterilization Card of Dog"
};

const ApplicationForm = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { addBtn } = location.state || {};
  const { selectedDog } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const { application } = useSelector((state) => state.application);
  const [toastVisible, setToastVisible] = useState(false);
  const initialPetState = {
    id: 1,
    applicationId: "",
    name: "",
    gender: "",
    age: "",
    colour: "",
    breed: "",
    vaccinationCard: "",
    sterilizationCard: "",
    veterinaryCard: "",
    stage: "Payment",
    files: {
      passport: "",
      ownerDog: "",
      ownerId: "",
      veterinary: "",
      vaccination: "",
      sterlization: "",
    },
  };
  const options = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: "book", label: "Book an appointment" },
  ]

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "female", label: "Female" },
  ]
  const [currentPet, setCurrentPet] = useState(localStorage.getItem("application")
    ? JSON.parse(localStorage.getItem("application"))?.[selectedDog ? selectedDog?.id - 1 : 0]
    : initialPetState
  );

  

  const handleFileChange = async (e, item) => {
    const file = e.target.files[0];
    if (!file) return;
    setIsLoading(true)
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_ROUTE}/user/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
      const imageUrl = response.data.url;
      

      const updatedPet = {
        ...currentPet,
        files: {
          ...currentPet.files,
          [item]: imageUrl,
        },
          };
          setCurrentPet(updatedPet);
          setPets(pets.map((pet) => (pet.id === updatedPet.id ? updatedPet : pet)));
          setIsLoading(false)

    } catch (error) {
      alert(error)
      console.error('Error uploading file', error);
    }
  }

  const handleRemoveImage = (label) => {
    const updatedPet = {
      ...currentPet,
      files: {
        ...currentPet.files,
        [label]: "",
      },
        };
        setCurrentPet(updatedPet);
        setPets(pets.map((pet) => (pet.id === updatedPet.id ? updatedPet : pet)));
};

  const [pets, setPets] = useState(localStorage.getItem("application") ? JSON.parse(localStorage.getItem("application")) : [currentPet]);
  // eslint-disable-next-line
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    localStorage.setItem("application", JSON.stringify(pets))

  const handleSterilizationSelect = (value) => {
    setCurrentPet({ ...currentPet, sterilizationCard: value });
    setPets(
      pets.map((pet) =>
        pet.id === currentPet.id ? { ...pet, sterilizationCard: value } : pet
      )
    );
  };
  const handleGenderSelect = (value) => {
    setCurrentPet({ ...currentPet, gender: value });
    setPets(
      pets.map((pet) =>
        pet.id === currentPet.id ? { ...pet, gender: value } : pet
      )
    );
  };

  const handleVaccinationSelect = (value) => {
    setCurrentPet({ ...currentPet, vaccinationCard: value });
    setPets(
      pets.map((pet) =>
        pet.id === currentPet.id ? { ...pet, vaccinationCard: value } : pet
      )
    );
  };
  
  const handleVeterinarySelect = (value) => {
    setCurrentPet({ ...currentPet, veterinaryCard: value });
    setPets(
      pets.map((pet) =>
        pet.id === currentPet.id ? { ...pet, veterinaryCard: value } : pet
      )
    );
  };
    // Updation of input Fields of Pet Details

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'age' && (Number(value) < 1 && value !== "" || isNaN(value))) {
      return;
    }
    const updatedPet = { ...currentPet, [name]: value };
    setCurrentPet(updatedPet);
    setPets(
      pets.map((pet, index) => (index + 1 === updatedPet.id ? updatedPet : pet))
    );
  };


  // Handle addition of new pets

  const addPet = () => {
    if (!pets.some((pet) => pet.id === currentPet.id)) {
      setPets([...pets, currentPet]);
    } else {
      setPets(pets.map((pet) => (pet.id === currentPet.id ? currentPet : pet)));
    }

    const newPetId = pets.length + 1;
    const newPet = { ...initialPetState, id: newPetId };
    setCurrentPet(newPet);
    setPets([...pets, newPet]);
  };

  // Handle selected Pet

  const selectPet = (pet) => {
    setCurrentPet(pet);
  };

  // Handle Next Button

  const handleNext = () => {
    let allDetailsFilled = true;

    pets.forEach((pet) => {
      if (
        pet.name === "" ||
        pet.gender === "" ||
        pet.colour === "" ||
        pet.breed === "" ||
        pet.age === "" ||
        pet.sterilizationCard === "" ||
        pet.vaccinationCard === "" ||
        pet.veterinaryCard === "" ||
        Object.keys(pet.files).forEach((key) => {
          console.log(pet.files)
          if (pet.files["passport"] === "" || pet.files["ownerDog"] === "" || pet.files["ownerId"] === "") {
            allDetailsFilled = false;
          }

          if ((pet.files["sterlization"] === "" && currentPet.sterilizationCard === "yes") || (pet.files["vaccination"] === "" && currentPet.vaccinationCard === "yes") || (pet.files["veterinary"] === "" && currentPet.veterinaryCard === "yes")) {
            allDetailsFilled = false;
          }

          

        })
      ){
        allDetailsFilled = false;
        return;
      }
    });

    if (allDetailsFilled) {
      navigate("/declaration");
      dispatch(clearAll());
      dispatch(addPets(pets));
    } else {
      setToastVisible(true);
      setTimeout(() => setToastVisible(false), 5000)
    }
  };

  // Info modal on enclosures
  const handleInfo = (index) => {
    // eslint-disable-next-line
    // {
    //   selectedImageIndex != null
    //     ? setSelectedImageIndex(null)
    //     : setSelectedImageIndex(index);
    // }
  };

  return (
    <div className="min-h-screen p-4 flex flex-col items-center justify-center">
      <Logo />
      {toastVisible && <Toast message="Please Fill out all the Pet Details and upload all Images for all Pets" />}
      <div className="w-full max-w-5xl mb-2 flex justify-start items-center">
        <img
          src={back}
          onClick={() => navigate(-1)}
          alt="Dog"
          className={`w-6 h-6 items-center mb-2 mr-6 cursor-pointer`}
        />
        <h1 className="text-3xl font-semibold mb-3">Application Form</h1>
      </div>
      
      <div className="flex w-full flex-wrap max-w-5xl justify-start items-center space-x-4 mb-4">
        {pets.map((pet, index) => (
          <div key={pet.id} className={` flex items-center  justify-center `}>
            <div className="flex flex-col">
              <img
                src={pet?.files?.passport === "" ? dog : pet.files.passport}
                alt="Dog"
                onClick={() => selectPet(pet)}
                className={`w-16 h-16 border-2 cursor-pointer rounded-full ${
                  currentPet.id === pet.id
                    ? "border-white"
                    : "border-transparent"
                }`}
              />
              <span className="text-center">
                {pet.name || `Dog ${index + 1}`}
              </span>
            </div>
          </div>
        ))}
        <button
          onClick={addPet}
          disabled={addBtn}
          className="h-16 w-16 mb-auto bg-gray-200 rounded-full flex items-center justify-center"
        >
          <span className="text-2xl">+</span>
        </button>
      </div>
      <div className="w-full max-w-screen-lg grid md:grid-cols-2 gap-4">
        <div className="p-4 py-3 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Pet Details</h2>
          <div className="grid grid-cols-2 gap-2 mb-4">
            <Input
              name={"name"}
              placeholderName={"Pet's Name"}
              val={currentPet.name}
              handleChange={handleInputChange}
            />
            <Dropdown
              name="Gender"
              val={currentPet.gender}
              onSelect={handleGenderSelect}
              options={genderOptions}
            />
            
            <input
              type="number"
              name="age"
              value={currentPet.age}
              onChange={handleInputChange}
              placeholder="Age"
              className="border p-2 rounded"
              min="1"
            />
            <Input
              name={"colour"}
              placeholderName={"Colour"}
              val={currentPet.colour}
              handleChange={handleInputChange}
            />
          </div>
          <div className="grid grid-cols-1 gap-2 mb-4">
            <Input
              name={"breed"}
              placeholderName={"Breed"}
              val={currentPet.breed}
              handleChange={handleInputChange}
            />
          </div>
          <div className="grid grid-cols-3 gap-2">
          <Dropdown
              name="Veterinary health Card"
              val={currentPet.veterinaryCard}
              onSelect={handleVeterinarySelect}
              options={options}
            />
           

            <Dropdown
              name="Vaccination Card"
              val={currentPet.vaccinationCard}
              onSelect={handleVaccinationSelect}
              options={options}
            />

<Dropdown
              name="Sterilization Card"
              val={currentPet.sterilizationCard}
              onSelect={handleSterilizationSelect}
              options={options}
            />

            
          </div>
        </div>
        <div className="p-4 py-3 bg-white rounded-lg shadow-md">
        <div className="flex">
          <h2 className="text-xl font-semibold mr-4 mb-4">Enclosure</h2> 
          {isLoading ? <Loader /> : ""}
        </div>
          <div className="grid grid-cols-3 gap-2">

           
            {Object.keys(enclosure).map((label, index) => (
              <div
                key={index}
                className="border flex flex-col bg-upl-bckg p-3 rounded relative">
              {currentPet?.files?.[label] !== "" ? (
                <div className="relative">
                
                  <img src={currentPet?.files?.[label]} alt={label} 
                  className="w-24 h-24 object-cover" />
                <button
                onClick={() => handleRemoveImage(label,index)}
                className="absolute top-0 right-0 bg-red-500 text-white px-2 rounded"
              >X</button>
              </div>
              ): (
                <>
                <div className="flex mb-2">
                  <span className="block text-xs mb-1">{enclosure[label]}</span>
                  <img
                    src={info}
                    alt="Info"
                    onClick={() => handleInfo(index)}
                    className="w-4 h-4 ml-auto cursor-pointer"
                  />
                </div>
                {selectedImageIndex === index && (
                  <div className="bg-white z-10 p-1 top-7 right-7 absolute rounded">
                    <p className="text-xs">Lorem ipsum</p>
                    <p className="text-xs underline text-blue-800">see more.</p>
                  </div>
                )}
                <div className={`flex ${(enclosure[label] === "Vaccination Card Of Dog" && currentPet.vaccinationCard !== "yes") || (enclosure[label] === "Veterinary Health Certificate Of Dog" && currentPet.veterinaryCard !== "yes") || (enclosure[label] === "Sterilization Card of Dog" && currentPet.sterilizationCard !== "yes") ? "bg-gray-300": ""} justify-center mt-auto relative`}>
                  <input
                    type="file"
                    disabled={(enclosure[label] === "Vaccination Card Of Dog" && currentPet.vaccinationCard !== "yes") || (enclosure[label] === "Veterinary Health Certificate Of Dog" && currentPet.veterinaryCard !== "yes") || (enclosure[label] === "Sterilization Card of Dog" && currentPet.sterilizationCard !== "yes")}
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, label)}
                    className="absolute inset-0 cursor-pointer opacity-0"
                  />
                  <div className=" p-1 rounded mt-auto flex items-center justify-center">
                    <img src={upload} alt="Upload" className="w-6 h-6" />
                  </div>
                </div>
                </>)}
              </div>
            ))}


          </div>
        </div>
      </div>
      <div className="w-full max-w-5xl flex justify-start items-center ">
        <p className="">*Once submitted form will not be edited again.</p>
        <Button
          handleClick={handleNext}
          name={"Next"}
          classNames={"ml-auto mt-4 px-6 bg-btn-blue text-black"}
        />
      </div>
    </div>
  );
};

export default ApplicationForm;
