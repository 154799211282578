import React, { useState } from "react";
import dogProfile from "../../assets/dog/dog profile(1).png";
import Logo from "../General/Logo";
import appIcon from "../../assets/icon/article.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import McgApplication from "../General/McgApplication";
import { useLocation } from "react-router-dom";

const ApplicationStatus = () => {
  const { state } = useLocation();
  const [selectedDog, setSelectedDog] = useState(state?.[0]);
  const [showComponent, setShowComponent] = useState(false);
  const handleDownload = async () => {
    const input = document.getElementById("component-to-download");
    await html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
      });
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save("Application.pdf");
      setShowComponent(false);
    });
  };

  const handleDownloadClick = () => {
    setShowComponent(true);
    setTimeout(handleDownload, 0);
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <Logo />
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-4xl font-semibold mb-3">Application Status</h1>
      </div>
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-xl  mb-3">Your application has been submitted</h1>
      </div>
      <div className="flex w-full justify-start max-w-5xl space-x-8 mb-6">
        {state?.map((dog) => (
          <div key={dog.dogId} className="flex flex-col items-center">
            <img
              src={dogProfile}
              alt={dog.dogName}
              className={`w-16 h-16 rounded-full border-2 cursor-pointer ${
                selectedDog.dogId === dog.dogId
                  ? "border-white"
                  : "border-transparent"
              }`}
              onClick={() => setSelectedDog(dog)}
            />
            <span className="mt-2 text-sm mb-8 font-semibold">
              {dog.dogName}
            </span>
          </div>
        ))}
      </div>

      <div className="w-full max-w-5xl space-y-4">
        <div className="bg-status-blue px-14 p-4 py-2 rounded-lg shadow-lg">
          <div className="">
            <div className="flex items-center">
              <span className="font-semibold">
                Please take note of your Application Id -{" "}
                {selectedDog?.applicationID}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex max-w-5xl w-full mt-8">
        <div className={`flex flex-col bg-white p-2 w-1/2 rounded`}>
          <p
            onClick={() => setShowComponent(!showComponent)}
            className={`underline text-xs cursor-pointer ml-auto `}
          >
            View
          </p>
          <div className="flex">
            <img src={appIcon} alt="icon" className={`w-4 mt-1 h-4`} />
            <p className="ml-2 font-semibold">Application</p>
          </div>
          <p
            onClick={handleDownloadClick}
            className="underline text-xs cursor-pointer ml-auto"
          >
            Download
          </p>
        </div>
      </div>

      <div className="flex max-w-5xl w-full my-4 overflow-x-auto">
        {showComponent && (
          <McgApplication
            dogImage={state ? selectedDog?.files?.passport : ""}
            dogName={state ? selectedDog.dogName : ""}
            Gender={state ? selectedDog.dogGender : ""}
            Breed={state ? selectedDog.dogBreed : ""}
            Colour={state ? selectedDog.dogColor : ""}
            Age={state ? selectedDog.dogAge : ""}
            vaccinationCard={state ? selectedDog.vaccinationCard : ""}
            sterilizationCard={state ? selectedDog.sterLizationCard : ""}
            ownerName={state ? selectedDog.ownerName : ""}
            ownerFName={state ? selectedDog.ownerFName : ""}
            ownerEmail={state ? selectedDog.ownerEmail : ""}
            ownerContactNo={state ? selectedDog.ownerContactNo : ""}
            ownerAddress={state ? selectedDog.ownerAddress : ""}
          />
        )}
      </div>
    </div>
  );
};

export default ApplicationStatus;
