import React, { useState } from "react";
import Logo from "../General/Logo";
import appIcon from "../../assets/icon/article.png";
import upload from "../../assets/icon/upload.png";
import tick from "../../assets/icon/tick.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import McgApplication from "../General/McgApplication";
import Button from "../General/Button";
import { useLocation } from "react-router-dom";

const ApplicationIds = () => {
  const { state } = useLocation();
  const [showComponent, setShowComponent] = useState(false);
const [image, setImage] = useState([])
  const [currentStage, setCurrentStage] = useState(
    state?.result?.getData?.statusRoadmap?.payment ||
     state?.result?.getData?.statusRoadmap?.mcg ||
     state?.result?.getData?.statusRoadmap?.certificate ||
      "pending"
  );
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(file)
    } else {
      alert("Please upload a valid image file (jpg, png, etc.)");
    }
  };

  const handleDownload = async () => {
    const input = document.getElementById("component-to-download");
    await html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height],
        compress: true
      });
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save("Application.pdf");
      setShowComponent(false);
    });
  };
  const handleSubmit = () => {
    const selectedFile = image;
    if (selectedFile) {
      setCurrentStage("Certificate");
    } else {
      alert("Please upload an image before submitting");
    }
  };
  const handleReview = () => {
    setCurrentStage("Review2");
  };

  const handleDownloadClick = () => {
    setShowComponent(true);
    setTimeout(handleDownload, 0); // Ensure the component is rendered before triggering print
  };

  return (
    <div className="min-h-screen flex flex-col items-center p-6">
      <Logo />
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-4xl font-semibold mb-3">Application Status</h1>
      </div>
      <div className="w-full max-w-5xl flex justify-start items-center">
        <h1 className="text-xl  mb-3">Your application has been submitted</h1>
      </div>
      <div className="flex w-full justify-start max-w-5xl space-x-8 mb-6">
        
          <div className="flex flex-col items-center">
            <img
              src={state?.result?.getData?.dog?.images?.passport}
              alt={state?.result?.getData?.dog?.name}
              className={`w-16 h-16 rounded-full border-2 cursor-pointer       
                  "border-white"
              `}
            />
            <span className="mt-2 text-sm font-semibold">{state?.result.getData.dog.name}</span>
          </div>
       
      </div>

      <div className="w-full max-w-5xl space-y-4">
        <div className="bg-status-blue px-14 p-4 py-2 rounded-lg shadow-lg">
          <div className="mb-4">
            <div className="flex mb-8 justify-between items-center">
              <span className="font-semibold">ID- {state?.result.getData.applicationID}</span>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <div className="flex flex-col cursor-pointer relative items-center">
              <div
                className={`w-10 h-10 relative rounded-full ${
                  currentStage === "pending" ? "bg-red-500" : "bg-st-circle"
                }`}
              >
                {currentStage === "pending" ? (
                  <div
                    className={`w-5 h-5 border-2 border-white absolute top-1/4 left-1/4 rounded-full ${
                      currentStage === "pending"
                        ? "bg-red-500"
                        : "bg-gray-400"
                    }`}
                  ></div>
                ) : (
                  <img
                    src={tick}
                    alt="icon"
                    className={`w-4 absolute top-1/4 left-1/4 mt-1 h-4`}
                  />
                )}
              </div>
              <span className={`absolute top-10`}>
                {currentStage === "pending" ? "Payment_Pending" : "Payment"}
              </span>
            </div>
            <div className="flex-1 border-t-2 border-st-circle"></div>
            <div className="flex flex-col cursor-pointer relative items-center">
              <div
                className={`w-4 h-4 rounded-full ${
                  currentStage !== "pending"  ? "bg-green-500" : "bg-gray-400"
                }`}
              ></div>
              <span className={`absolute top-5`}>Review</span>
            </div>
            <div className="flex-1 border-t-2 border-st-circle"></div>
            <div className="flex flex-col cursor-pointer relative items-center">
              <div
                className={`w-10 h-10 relative rounded-full ${
                  currentStage !== "pending" && currentStage !== "success" ? "bg-st-circle" : "bg-gray-400"
                }`}
              >
                <div
                  className={`w-5 h-5 border-2 border-white absolute top-1/4 left-1/4 rounded-full ${
                    currentStage !== "pending" && currentStage !== "success"
                      ? "bg-st-circle"
                      : "bg-gray-400"
                  }`}
                ></div>
              </div>
              <span className={`absolute top-10`}>MCG</span>
            </div>
            <div className="flex-1 border-t-2 border-st-circle"></div>
            <div
              onClick={currentStage === "mcg" ? handleReview : null}
              className="flex flex-col relative cursor-pointer items-center"
            >
              <div
                className={`w-10 h-10 relative rounded-full ${
                  currentStage === "Certificate" && "bg-st-circle"
                } ${currentStage === "Review2" ? "bg-red-500" : "bg-gray-400"}`}
              >
                <div
                  className={`w-5 h-5 border-2 border-white absolute top-1/4 left-1/4 rounded-full ${
                    currentStage !== "Review2" &&
                    currentStage !== "Certificate" &&
                    "bg-gray-300"
                  }  ${currentStage === "Review2" && "bg-red-500"} ${
                    currentStage === "Certificate" && "bg-st-circle"
                  }`}
                ></div>
              </div>
              <span className={`absolute top-10`}>Review</span>
            </div>
            <div className="flex-1 border-t-2 border-st-circle"></div>
            <div className="flex cursor-pointer flex-col relative items-center">
              <div
                className={`w-10 h-10 relative rounded-full ${
                  currentStage !== "Certificate"
                    ? "bg-gray-400"
                    : "bg-green-500"
                }`}
              >
                <div
                  className={`w-5 h-5 border-2 border-white absolute top-1/4 left-1/4 rounded-full ${
                    currentStage !== "Certificate"
                      ? "bg-gray-300"
                      : "bg-green-500"
                  }`}
                ></div>
              </div>
              <span className={`absolute top-10`}>Certificate</span>
            </div>
          </div>
          <div className="flex mt-10 text-gray-500 text-sm">
            <span className="text-gray-500 text-sm">
              Last updated- {state?.result?.getData?.createdAt}
            </span>
            <span className="ml-auto">*It may take 25 days</span>
          </div>
        </div>
      </div>

      {currentStage === "Review2" && (
        <div className="flex max-w-5xl w-full justify-start mt-10">
          <div className="p-6 py-4 w-3/4 bg-rev-bckg rounded-lg">
            <h2 className="text-lg font-semibold">
              Veterinary Health Certificate Of Dog
            </h2>

            <div className="flex">
              <p className="mt-2">Remark -</p>
              <p className="w-1/2 mt-2 ml-1">
                {" "}
                Please reupload clear image of Veterinary Health Certificate.
              </p>
              <div className="mt-4 flex items-center">
                <input
                  type="file"
                  accept="image/*"
                  id="upload"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="upload"
                  className="mr-4 bg-white flex px-4 py-1 rounded shadow cursor-pointer hover:bg-gray-100"
                >
                  <img
                    src={upload}
                    alt="icon"
                    className={`w-4 m-2 mt-1 ml-0 h-4`}
                  />
                  Upload
                </label>
              </div>

              <Button
                handleClick={handleSubmit}
                name={"Submit"}
                classNames={
                  "bg-white ml-auto px-6 py-3 mt-4 rounded shadow hover:bg-gray-100"
                }
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex max-w-5xl w-full mt-8">
        {currentStage === "Certificate" && (
          <div className="flex flex-col bg-white p-2 w-1/2 mr-40 rounded">
            <p className="underline text-xs cursor-pointer ml-auto">View</p>
            <div className="flex">
              <img src={appIcon} alt="icon" className={`w-4 h-4 mt-1`} />
              <p className="ml-2 font-semibold">Certificate</p>
            </div>

            <p className="underline text-xs cursor-pointer ml-auto">Download</p>
          </div>
        )}

        <div
          className={`flex flex-col ${
            currentStage === "Certificate" ? "ml-auto" : ""
          } bg-white p-2 w-1/2 rounded`}>
          <p onClick={() => setShowComponent(!showComponent)}
            className={`underline text-xs cursor-pointer ml-auto `}>
            View
          </p>
          <div className="flex">
            <img src={appIcon} alt="icon" className={`w-4 mt-1 h-4`} />
            <p className="ml-2 font-semibold">Application</p>
          </div>
          <p
            onClick={handleDownloadClick}
            className="underline text-xs cursor-pointer ml-auto"
          >
            Download
          </p>
        </div>
      </div>

          <div className="flex max-w-5xl w-full my-4 overflow-x-auto">
        {showComponent && (
          <McgApplication 
                dogImage={state?.result?.getData ? state.result.getData.dog?.files?.passport: ""}
                dogName={state?.result?.getData ? state.result.getData.dog.name: ""} 
                Gender={state?.result?.getData ? state.result.getData.dog.gender: ""}
                Breed={state?.result?.getData ? state.result.getData.dog.breed: ""}
                Colour={state?.result?.getData ? state.result.getData.dog.name: ""}
                Age={state?.result?.getData ? state.result.getData.dog.age: ""}
                vaccinationCard={state?.result?.getData ? state.result.getData.dog.vaccinationCard: ""}
                sterilizationCard={state?.result?.getData ? state.result.getData.dog.sterLizationCard: ""}
                ownerName={state?.result?.getData ? state.result.getData.owner.name: ""}
                ownerFName={state?.result?.getData ? state.result.getData.owner.fName: ""}
                ownerEmail={state?.result?.getData ? state.result.getData.owner.email: ""}
                ownerContactNo={state?.result?.getData ? state.result.getData.owner.contactNo: ""}
                ownerAddress={state?.result?.getData ? state.result.getData.owner.address: ""}
               />
        )}
      </div>
    </div>
  );
};

export default ApplicationIds;
