import { createSlice } from '@reduxjs/toolkit';


const applicationSlice = createSlice({
    name: 'application',
    initialState: {
      application: [],
      fromDetails:false
    },
    reducers: {
      addPets: (state, action) => {
        state.application.push(action.payload);
      },
      UpdateStage:(state, action) =>{
        return {
          ...state,
          application: state.application.map((appArray, index) =>
            index === 0
              ? appArray.map((app) => ({
                  ...app,
                  stage: action.payload,
                }))
              : appArray
          ),
        };
      },
      deletePet:(state, action) =>{
        state.application[0]?.splice(action.payload, 1)
      },
      clearAll:(state) =>{
        state.application = []
      },
      // setContent: (state, action) => action.payload
    },
  });


export const { addPets, UpdateStage,clearAll,deletePet } = applicationSlice.actions;
export default applicationSlice.reducer;