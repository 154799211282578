import React from 'react';

function Loader() {

  return (
    <div className="flex flex-col items-center justify-center">
        <div className="loader border-t-4 border-blue-500 rounded-full w-6 h-6 animate-spin"></div>
    </div>
  );
}

export default Loader;
