import React, { useState, useEffect, useRef } from 'react';

const Dropdown = ({name,onSelect, val, options }) => {
  const [selectedOption, setSelectedOption] = useState(val);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(val)
  }, [val])
  
  const dropdownRef = useRef(null);
  

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setIsOpen(false);
    if (onSelect) {
      onSelect(value);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-3 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={() => setIsOpen(!isOpen)}
        >
        
          {selectedOption ? options.find(option => option.value === selectedOption)?.label : name}
          <svg
            className="fill-current ml-auto h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 11.828 5.757 6.485 4.343 7.901z" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right z-10 absolute mt-2 w-full p-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {options.map(option => (
              <button
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                className=" w-full flex text-wrap border-2 border-gray-400 rounded my-2 overflow-x-auto text-left px-4 py-3 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none"
                role="menuitem"
              >
                {option?.label}
                <span className='h-4 w-4 ml-auto p-2 border-2 rounded-full border-gray-400 '></span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
