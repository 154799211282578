import { createSlice } from '@reduxjs/toolkit';


const ownerDetailsSlice = createSlice({
    name: 'ownerDetails',
    initialState: {
      ownerDetails: [],
    },
    reducers: {
      addDetails: (state, action) => {
        state.ownerDetails.push(action.payload);
      },
      clearAll:(state) =>{
        state.ownerDetails = [];
      }
    },
  });


export const { addDetails, clearAll } = ownerDetailsSlice.actions;
export default ownerDetailsSlice.reducer;