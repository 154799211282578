import './App.css';
import { BrowserRouter} from "react-router-dom";
import { Route, Routes, Navigate} from "react-router-dom";
import { Provider } from "react-redux";

import ApplicationForm from './components/Applications/ApplicationForm';
import LandingPage from './components/LandingPage';
import OwnerDetails from './components/Owner/OwnerDetails';
import DeclarationForm from './components/Declarations/Declaration';
import Preview from './components/Declarations/Preview';
import store from './redux/store';
import ApplicationStatus from './components/Applications/ApplicationStatus';
import ApplicationIds from './components/Applications/ApplicationIds';
import PaymentCart from './components/Payment/PaymentCart';
import ApplicationList from './components/Applications/ApplicationList';

function App() {
  return (
    <div className="App">
    <Provider store={store}>
    <BrowserRouter>
    <Routes>
    <Route exact path="/" element={<LandingPage/>} />
    <Route exact path="/ownerDetails" element={<OwnerDetails/>} />
    <Route exact path="/application" element={<ApplicationForm/>} />
    <Route exact path="/declaration" element={<DeclarationForm/>} />
    <Route exact path="/preview" element={<Preview/>} />
    <Route exact path="/payment" element={<PaymentCart/>} />
    <Route exact path="/applicationStatus" element={<ApplicationStatus/>} />
    <Route exact path="/applicationList" element={<ApplicationList/>} />
    <Route exact path="/applicationIds" element={<ApplicationIds/>} />
    <Route exact path="*" element={<Navigate to="/" />} />
    </Routes>
    </BrowserRouter>
    </Provider>
    </div>
  );
}

export default App;
