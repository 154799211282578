import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import dogHug from "../assets/main/dogHug.png";
import search from "../assets/icon/search.png";
import Logo from "./General/Logo";
import Button from "./General/Button";


const Home = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleApplication = async () => {

    const url = `${process.env.REACT_APP_ROUTE}/user/getdetails`

    try {
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email: email}),
      });

      const result = await res.json();
      if(result.status){
        navigate("/applicationList", {state : {result}})
      }else {
        alert(result.message)
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Invalid Application Id")
      return
    }
  }
  return (
    <div className="flex flex-col md:flex-row min-h-screen max-h-screen">
      <div className="md:w-1/2 flex flex-col md:p-8 p-4">
        <Logo />
        <div className="flex flex-col justify-center mt-10 lg:px-28">
          <h1 className="text-3xl lg:text-4xl font-bold mb-4">
            Certify Your Dog{" "}
            <span role="img" aria-label="dog">
              🐶
            </span>
          </h1>
          <h1 className="font-bold mt-2 mb-4">Give Your Furry Friend the Best!</h1>
          <p className="text-gray-700 mb-6 text-sm">
          Join FourPaws and go beyond basic care! Register your dog for safety alerts,
           tailored health tips, and access to vibrant community events.
           Celebrate your pet’s journey with all the support and love they deserve.
          </p>
          
          <Button handleClick={() => navigate("/ownerDetails")} name={"Register Your Pet"} classNames={"bg-regal-blue text-white py-2 px-4 w-full mb-6"}/>

          <div className="w-full mt-12">
            <h2 className="text-lg font-semibold mb-2">Already registered?</h2>
            <div className="relative">
              <input
                type="text"
                name="email"
                value={email}
                onChange={handleInputChange}
                className="w-full bg-btn-blue rounded px-4 py-2 outline-none"
                placeholder="Your Email"
              />
              <img
              onClick={handleApplication}
                src={search}
                alt="search"
                className="w-4 h-4 absolute cursor-pointer top-3 right-3 object-cover"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-1/2 p-4">
        <img
          src={dogHug}
          alt="Dog Hug"
          className="w-full h-full rounded-lg object-cover"
        />
      </div>
    </div>
  );
};

export default Home;
